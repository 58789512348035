let BASE_URL = 'https://api.pharma.sobrus.ovh';
let REDDIREC_URI = 'https://api.pharma.sobrus.ovh/auth/redirect-to-idp-login';
let DOM_NAME = 'https://app.pharma.sobrus.ovh';
let EXT = 'ovh';
let APP_STORE_DEEP_LINK_OFFER = 'com.sobrus.pharma.staging://OfferDetails/';
let APP_STORE_DEEP_LINK_OFFERS = 'com.sobrus.pharma.staging://Offers';
const PLAY_STORE_DEEP_LINK_OFFER = 'com.sobrus_pharma://OfferDetails/';
const PLAY_STORE_DEEP_LINK_OFFERS = 'com.sobrus_pharma://Offers';
let APP_STORE_LINK = 'https://apps.apple.com/ma/app/sobrus-pharma/id1503665429';
let PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.sobrus_pharma';
let VITE_GA_MEASUREMENT_ID = 'G-R1KCFS63XP';
if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
  BASE_URL = 'https://api.pharma.sobrus.com';
  REDDIREC_URI = 'https://api.pharma.sobrus.com/auth/redirect-to-idp-login';
  EXT = 'com';
  DOM_NAME = 'https://app.pharma.sobrus.com';
  APP_STORE_DEEP_LINK_OFFER = 'com.sobrus.pharma.prod://OfferDetails/';
  APP_STORE_DEEP_LINK_OFFERS = 'com.sobrus.pharma.prod://Offers';
  APP_STORE_LINK = 'https://apps.apple.com/ma/app/sobrus-pharma/id1503665429';
  PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.sobrus_pharma';
  VITE_GA_MEASUREMENT_ID = 'G-R1KCFS63XP';
}

export {
  BASE_URL,
  REDDIREC_URI,
  EXT,
  DOM_NAME,
  APP_STORE_DEEP_LINK_OFFERS,
  PLAY_STORE_DEEP_LINK_OFFERS,
  APP_STORE_DEEP_LINK_OFFER,
  PLAY_STORE_DEEP_LINK_OFFER,
  APP_STORE_LINK,
  PLAY_STORE_LINK,
  VITE_GA_MEASUREMENT_ID,
};
