import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import tokenReducer from './features/tokenSlice';
import tableDataReducer from './features/tableDataSlice';
import optionsDataReducer from './features/optionsSlice';
// import optionReducer from './features/optionSlice';
import offersReducer from './features/offersSlice';
import companyParamsReducer from './features/companyParamasSlice';
import profileReducer from './features/ProfileSlice';
import licenseReducer from './features/licenceSlice';
import notificationsReducer from './features/notificationSlice';
import reportsReducer from './features/reportsAcl';
import offlineReducer from './features/offlineData';

export const store = configureStore({
  reducer: {
    user: userReducer,
    token: tokenReducer,
    dataTable: tableDataReducer,
    optionsData: optionsDataReducer,
    offers: offersReducer,
    companyParams: companyParamsReducer,
    profile: profileReducer,
    license: licenseReducer,
    notifications: notificationsReducer,
    reports: reportsReducer,
    offlinedata: offlineReducer,
  },
  devTools: import.meta.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
