import localforage from 'localforage';

export const invoicesOfflineLocalforage = localforage.createInstance({
  name: 'invoices',
  storeName: 'offline',
});

export const offlineUserLocalforage = localforage.createInstance({
  name: 'user',
  storeName: 'user-info',
});
export const optionsLocalforage = localforage.createInstance({
  name: 'options',
  storeName: 'options-store',
});
export const docLocalforage = localforage.createInstance({
  name: 'doc',
  storeName: 'options-store',
});

export const creationsLocalforage = localforage.createInstance({
  name: 'creations',
  storeName: 'creations',
});

export const offlinecustomersLocalforage = localforage.createInstance({
  name: 'offline-data',
  storeName: 'offlinecustomers',
});
export const offlineProductsLocalforage = localforage.createInstance({
  name: 'offline-data',
  storeName: 'offlineproducts',
});

export const errorHandler = (error: any, name: string) => {
  if (error instanceof DOMException || error.name === 'QuotaExceededError' || error.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
    localStorage.setItem('__offline__space', 'false');
  } else {
    localStorage.setItem(name, 'false');
  }
};
export const validationHandler = (name: string) => {
  localStorage.setItem(name, 'true');
  localStorage.setItem('__offline__space', 'true');
};
