import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@/types';

export interface UserState {
  user: User;
  loading: boolean;
  error: string;
}

const initialState: Readonly<UserState> = {
  user: {} as User,
  loading: true,
  error: '',
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOut: () => initialState,
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.loading = false;
    },
    seUserLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setUser, seUserLoading, logOut } = user.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default user.reducer;
