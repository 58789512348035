import { Component, ErrorInfo, LazyExoticComponent, ReactNode } from 'react';
import Error from '@/pages/ErrorBoundary';
import * as Sentry from '@sentry/react';
import { ErrorModal } from '@/pages/ErrorBoundary/ErrorBoundary';
interface ErrorBoundaryProps {
  children: ReactNode;
  notDisplay?: boolean;
  type?: 'modal' | 'page' | 'section' | 'nav';
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: string;
  _errorInfo: string;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: '', _errorInfo: '' };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }
  errorInfoToObject(errorInfo: ErrorInfo): { [key: string]: any } {
    return {
      componentStack: errorInfo.componentStack,
    };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const extra = this.errorInfoToObject(errorInfo);
    this.setState({
      error: JSON.stringify(error),
      _errorInfo: JSON.stringify(errorInfo),
    });
    Sentry.captureException(error, { extra });
  }

  render() {
    const { hasError } = this.state;
    const { children, type } = this.props;

    if (hasError) {
      if (type === 'modal') {
        return <ErrorModal />;
      }
      return <Error type={type} />;
    }

    return children;
  }
}
export const ErrorBoundaryPages = (CurrentComponent: LazyExoticComponent<() => JSX.Element>) => {
  class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false, error: '', _errorInfo: '' };
    }
    errorInfoToObject(errorInfo: ErrorInfo): { [key: string]: any } {
      return {
        componentStack: errorInfo.componentStack,
      };
    }
    static getDerivedStateFromError(_: Error) {
      return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      const extra = this.errorInfoToObject(errorInfo);
      this.setState({
        error: JSON.stringify(error),
        _errorInfo: JSON.stringify(errorInfo),
      });
      Sentry.captureException(error, { extra });
    }

    render() {
      const { hasError } = this.state;

      if (hasError) {
        return <Error />;
      }

      return <CurrentComponent />;
    }
  }
  return ErrorBoundary;
};
