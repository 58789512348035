import { LicenseData } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LicenseState {
  license: LicenseData;
  loading: boolean;
}

const initialState: Readonly<LicenseState> = {
  license: {} as LicenseData,
  loading: true,
};

const license = createSlice({
  name: 'license',
  initialState,
  reducers: {
    setLicence: (state, action: PayloadAction<LicenseData>) => {
      state.license = action.payload;
      state.loading = false;
    },
    setLicenseLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setLicence, setLicenseLoading } = license.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default license.reducer;
