import { ProfilData } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProfileState {
  profile: ProfilData;
  loading: boolean;
}

const initialState: Readonly<ProfileState> = {
  profile: {} as ProfilData,
  loading: true,
};

const token = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<ProfilData>) => {
      state.profile = action.payload;
      state.loading = false;
    },
    setProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setProfile, setProfileLoading } = token.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default token.reducer;
