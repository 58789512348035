import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OfflineDataTypes<T> {
  [key: string]: T;
}

interface OfflineDataState<T> {
  offlineData: OfflineDataTypes<T>;
}

const initialState: Readonly<OfflineDataState<any>> = {
  offlineData: {},
};

// const keyValue = ()

const offLineData = createSlice({
  name: 'offlineTable',
  initialState,
  reducers: {
    setOfflineData: (state, action: PayloadAction<any>) => {
      const [key] = Object.keys(action.payload);
      const [value] = Object.values(action.payload);
      if (state.offlineData[key]?.data) state.offlineData[key].data = value;
      else
        state.offlineData = {
          ...state.offlineData,
          [key]: value,
        };
    },
  },
});

export const { setOfflineData } = offLineData.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default offLineData.reducer;
