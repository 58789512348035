import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

interface OptionsDataType<T> {
  [key: string]: { data: T; loading: boolean; error: string };
}

interface DataState<T> {
  optionsData: OptionsDataType<T>;
}

const initialState: Readonly<DataState<any>> = {
  optionsData: {},
};

// const keyValue = ()

const optionsData = createSlice({
  name: 'optionsData',
  initialState,
  reducers: {
    createDataStorageName: (state, action: PayloadAction<string>) => {
      state.optionsData = {
        ...state.optionsData,
        [action.payload]: { data: [], loading: true, error: '' },
      };
    },
    setOptionsData: (state, action: PayloadAction<any>) => {
      const [key] = Object.keys(action.payload);
      const [value] = Object.values(action.payload);
      state.optionsData[key].data = value;
    },

    setOptionsDataLoading: (state, action: PayloadAction<{ nameStorage: string; loading: boolean }>) => {
      if ('loading' in state.optionsData[action.payload.nameStorage])
        state.optionsData[action.payload.nameStorage].loading = action.payload.loading;
    },
  },
});

export const { setOptionsData, setOptionsDataLoading, createDataStorageName } = optionsData.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default optionsData.reducer;
