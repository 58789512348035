import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ReportsState {
  reportsACL: string[];
  notificationLoading: boolean;
  reportsLength: number;
  error: string;
}

const initialState: Readonly<ReportsState> = {
  reportsACL: [] as string[],
  notificationLoading: true,
  reportsLength: 0,
  error: '',
};

const reports = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setreports: (state, action: PayloadAction<string[]>) => {
      state.reportsACL = action.payload;
    },
    setreportsLength: (state, action: PayloadAction<number>) => {
      state.reportsLength = action.payload;
    },
    setreportsLoading: (state, action: PayloadAction<boolean>) => {
      state.notificationLoading = action.payload;
    },
  },
});

export const { setreports, setreportsLoading, setreportsLength } = reports.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default reports.reducer;
